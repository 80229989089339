export const scale = {
  hidden: {
    scale: 0,
  },
  show: {
    scale: 1,
  },
  transition: {
    duration: 0.5,
  },
  exit: {
    scale: 0,
  },
};
